import { Navbar } from "./home"

export default function PP() {

    return (
        <div>
            <Navbar/>
            <div className="privacy-policy">

            
            <h1>Privacy Policy</h1>
    <p><strong>Last Updated: 26 Feb, 2023</strong></p>
    
    <h2>Your Privacy Matters</h2>
    <p>This Privacy Policy explains how Yujin collects, uses, and protects your information when you use our Discord bot. By using Yujin, you agree to the collection and use of information in accordance with this policy.</p>
    
    <h2>Information We Collect</h2>
    <p><strong>User IDs:</strong></p>
    <p>We collect your Discord user ID to keep track of your progress within the bot. This includes your collected cards, scores, and any other in-bot achievements.</p>
    
    <h2>How We Use Your Information</h2>
    <p>We use the information we collect in the following ways:</p>
    <ul>
        <li><strong>Progress Tracking:</strong> To keep track of your progress and achievements within the bot.</li>
    </ul>
    
    <h2>Information Sharing and Disclosure</h2>
    <p>We do not share, sell, or disclose your information to third parties except in the following cases:</p>
    <ul>
        <li><strong>Legal Requirements:</strong> If required by law, we may disclose your information to comply with a legal obligation.</li>
        <li><strong>Protection of Rights:</strong> To protect and defend our rights or property, or to investigate and prevent potential illegal activities.</li>
    </ul>
    
    <h2>Data Security</h2>
    <p>We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>
    
    <h2>Data Retention</h2>
    <p>We retain your user ID for as long as you use the bot. If you stop using the bot, your data will be retained for a period of 6 months before it is deleted from our records.</p>
    
    <h2>Your Rights</h2>
    <p>You have the right to:</p>
    <ul>
        <li><strong>Access Your Information:</strong> Request access to the information we hold about you.</li>
        <li><strong>Request Deletion:</strong> Request that we delete the information we hold about you.</li>
    </ul>
    <p>To exercise these rights, please contact us at <a href="https://discord.gg/yujinsoasis">Our Support Server</a>.</p>
    
    <h2>Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
    
    <h2>Contact Us</h2>
    <p>If you have any questions about this Privacy Policy, please contact us at <a href="https://discord.gg/yujinsoasis">Our Support Server</a>.</p>
    
    <p><strong>By using Yujin, you agree to this Privacy Policy.</strong></p>
    </div>
        </div>
    )
}