import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { memo } from "react";
import axios from "axios";
import './main.css'

const Navbar = memo(function NavbarComp() {
    return (
        <div>
            <div className="navbar">
                <div className="logo">
                    Yujin
                </div>
                <div className="navbar-middle">
                    <a href="/">Home</a>
                    <a href="/commands">Commands</a>
                    <a href="https://discord.gg/yujinsoasis">Support</a>
                    <a href="https://www.patreon.com/user?u=78851656">Patreon</a>
                    <a href="/cards">Cards</a>
                </div>
                <div className="navbar-right">
                    <a href="https://discord.com/oauth2/authorize?client_id=916587441526296609&permissions=0&integration_type=0&scope=bot+applications.commands#">Invite</a>
                    {/*<LoginAlg/>*/}
                    <div className="hamburger" onClick={() => document.querySelector(".mobile-menu").classList.toggle("active")}>
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div> 
                </div>
            </div>
            <div className="mobile-menu">
                <a href="/">Home</a>
                <a href="/commands">Commands</a>
                <a href="https://discord.gg/yujinsoasis">Support</a>
                <a href="https://www.patreon.com/user?u=78851656">Patreon</a>
                <a href="/cards">Cards</a>
            </div>
        </div>
    )
})

function LoginAlg() {
    const [resp, setResp] = useState(<></>)
    useEffect(() => {
        axios({
            url: "https://yujinbot.xyz/api/login?redirectURL=" + window.location.href,
            method: "GET",
        })
        .then(res => {
            if (res.data.uri) {
                setResp(<a href={res.data.uri}>Login</a>)
            } else {
                setResp(<div><p>{res.data.global_name}</p></div>)
            }
        }).catch(err => {
            setResp(<a href="/">Login</a>)
        })
    }, [])
    return (resp)
}


export default function Home() {
    console.log(useLocation().pathname)
    return (
        <div>
            <Navbar/>
            <div className="content main">
                <div className="headings">
                    <h1>Yujin Discord Bot</h1>
                    <p>A k-pop card collecting discord bot dedicated to Choi Yujin from Kep1er. Drop, Collect and trade beautifully designed cards!</p>
                    <div className="links">
                        <a className="btn" href="https://discord.gg/yujinsoasis">Support Server</a>
                        <a className="btn" href="https://discord.com/oauth2/authorize?client_id=916587441526296609&permissions=0&integration_type=0&scope=bot+applications.commands">Invite</a>
                    </div>
                </div>
                <img src="/yujin.webp" alt="Yujin"/>
            </div>
            <div className="content features-container">
                <h1>Features</h1>
                <div className="features">
                    <div className="feature">
                        <div className="feature-info">
                            <h2>Collect Unique Cards</h2>
                            <p>Drop a set of 3 unique cards every 10 minutes, check your inventory and search for different cards by their groups, era and rarity!</p>
                        </div>
                        <img className="feature-img" src="/feature1.webp" alt="Feature 1"/>
                    </div>
                    <div className="feature">
                        <div className="feature-info">
                            <h2>Currency System</h2>
                            <p>The in-game currency, earn Petals by using daily command, completing tasks, selling cards, or voting for Yujin. Use Petals to buy cards, powerups, and other items!</p>
                        </div>
                        <img className="feature-img" src="/feature2.webp" alt="Feature 2"/>
                    </div>
                    <div className="feature">
                        <div className="feature-info">
                            <h2>Custom Cards</h2>
                            <p>Have our awesome card designers design your custom cards by either boosting the support server or supporting us on Patreon! Check out our patreon for more info!</p>
                            <a href="https://www.patreon.com/user?u=78851656" className="btn">Patreon</a>
                        </div>
                        <img className="feature-img" src="/arranged_cards.webp" alt="Premium cards"/>
                    </div>
                </div>
            </div>
            <div className="content support">
                <h1>Support Sever</h1>
                <p>Found a bug? Have a question? Or if you have any idea you that you want added to the bot please join our support server. We do frequent card giveaways and will be happy to help you out!</p>
                <a className="btn" href="https://discord.gg/yujinsoasis">Support Server</a>
            </div>
            <div className="footer">Owned by mostlysunny, developed by <a href="/">.moae</a></div>
        </div>
    )
}

export {Navbar}