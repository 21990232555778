import React from "react";
import axios from "axios";
import './main.css'
import { useSearchParams } from "react-router-dom";
import { Navbar } from "./home";
import { useState, useEffect, useRef } from "react";
import Modal from 'react-modal'


function InventoryFilters({opened, onClosed, invData, filters, setFilters, setPageNum}) {
    const [idol, setIdols] = useState(filters.idol)
    const [group, setGroup] = useState(filters.group)
    const [rarity, setRarity] = useState(filters.rarity)
    const [era, setEra] = useState(filters.era)

    const idols = [...new Set(invData.map(card => card.name.split(" (")[0]))]
    const groups = [...new Set(invData.map(card => card.grop))]
    const eras = [...new Set(invData.map(card => {return card.name.split(" (").length===2?card.name.split(" (")[1].substring(0, card.name.split(" (")[1].length-1):null}))]
    
    const handleSubmit = (e) => {
        e.preventDefault()
        const filtersSet = {
            idol: idol,
            group: group,
            rarity: rarity,
            era: era
        }
        setFilters(filtersSet)
        setPageNum(1)
        onClosed()
    }

    const clearFilters = (e) => {
        e.preventDefault()
        setIdols("")
        setGroup("")
        setRarity("")
        setEra("")
        const filtersSet = {
            idol: "",
            group: "",
            rarity: 0,
            era: ""
        }
        setFilters(filtersSet)
        setPageNum(1)
        onClosed()
    }
    
    return (
        <Modal isOpen={opened} className="filter-menu" onRequestClose={onClosed} contentLabel="Card Filters" style={{overlay: {backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 5}}}>
            <h2>Card Filters</h2>
            <div className="filters">
                <div className="filter">
                    <p>Idol</p>
                    <Autocomplete options={idols} setValue={setIdols} defaul={idol}/>
                </div>
                <div className="filter">
                    <p>Group</p>
                    <Autocomplete options={groups} setValue={setGroup} defaul={group}/>
                </div>
                <div className="filter filter-rarity">
                    <p>Rarity</p>
                    <input className="rarity-input" type="number" value={rarity} onChange={e => setRarity(e.target.value)} min={1} max={5}/>
                </div>
                <div className="filter">
                    <p>Era</p>
                    <Autocomplete options={eras} setValue={setEra} defaul={era}/>
                </div>
                <div className="fiter-submit-btns">
                    <button className="filter-submit btn" onClick={(e) => handleSubmit(e)}>Apply</button>
                    <button className="filter-submit clear" onClick={(e) => clearFilters(e)}>Clear filters</button>
                </div>
                
            </div>
        </Modal>
    )
}


function Autocomplete({options, setValue, untilset, defaul}) {

    const [inputValue, setInputValue] = useState(defaul ? defaul : "")
    const [suggestions, setSuggestions] = useState([])
    const [openSlide, setopenSlide] = useState(false)
    const dropdown = useRef(null)

    const handleValueChange = (event) => {
        const value = event.target.value
        setInputValue(value)
        if (untilset) {
            if (options.includes(value)) {
                setValue(value)
            }
        } else {
            setValue(value)
        }
        
        if (value.length > 0) {
            setSuggestions(options.filter(option => option && option.toLowerCase().startsWith(value.toLowerCase())))
        } else {
            setSuggestions(options)
        }
    }

    const closeOpenMenus = (e)=>{
        if(openSlide && !dropdown.current?.contains(e.target)){
          setopenSlide(false)
        }
    }

    document.addEventListener("mousedown", closeOpenMenus)

    const handleClick = (value) => {
        setopenSlide(false)
        setInputValue(value)
        setSuggestions([])
        setValue(value)
    }

    const handleFocus = () => {
        setopenSlide(true)
        if (inputValue.length === 0) {
            setSuggestions(options)
        }
    }

    return (
        <div className="autocomplete-wrapper">
            <input
                className="autocomplete-input"
                type="text"
                value={inputValue}
                onChange={handleValueChange}
                onFocus={handleFocus}
            />
            {suggestions.length > 0 && openSlide && (
                <ul className="suggestions-list" ref={dropdown}>
                {suggestions.map((suggestion, index) => (
                    <li className="suggestion" key={index} onClick={() => handleClick(suggestion)}>{suggestion}</li>
                ))}
                </ul>
            )}
        </div>
    )
}


function SimpleProfile( {user, inventorycards }) {
    const [userData, setUserData] = useState([])

    useEffect(() => {
        const fetchUserData = async () => {
            axios({
                url: "https://yujinbot.xyz/api/getuser?user=" + user,
                method: "GET",
            })
            .then(res => {
                setUserData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchUserData()
    }, [])
    const globalname = userData.global_name
    const username = userData.username
    const avatar = "https://cdn.discordapp.com/avatars/" + userData.id + "/" + userData.avatar + ".png"
    return (
        <div className="simple-profile">
            <div className="profile-stuff">
                <div className="avatar">
                    <img src={avatar} alt="User Avatar"></img>
                </div>
                <div className="username">
                    <h2>{globalname}</h2>
                    <h3>{username}</h3>
                </div>
            </div>
            
            <div className="totalcards">
                <h2>Total Cards:</h2>
                <h2>{inventorycards}</h2>
            </div>
        </div>
    )
}


function Pagination( {pagenum, totalpages, changePage} ) {
    const [inputValue, setInputValue] = useState('');
    let buttons = []
    buttons.push(1)
    if (pagenum > 3 && totalpages > 5) {
        buttons.push('...')
    }
    else {
        buttons.push(2)
    }

    if (totalpages > 3) {
        if (pagenum < totalpages - 2 && totalpages > 5) {
            if (pagenum < 4) {
                buttons.push(3)
            } else {
                buttons.push(pagenum)
            }
            buttons.push('...')
            buttons.push(totalpages)
            
        }
        else {
            buttons.push(totalpages-2)
            buttons.push(totalpages - 1)
            buttons.push(totalpages)
        }
    }

    if (totalpages < 6) {
        buttons.length = 0
        for (let i = 1; i <= totalpages; i++) {
            buttons.push(i)
        }
    }

    const handleClick = (event, data) => {
        if (data === 'b') {
            if (pagenum > 1) {
                changePage(pagenum - 1)
            }
        }
        else if (data === 'f') {
            if (pagenum < totalpages) {
                changePage(pagenum + 1)
            }
        } else {
            changePage(data)
        }
    }

    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            changePage(parseInt(inputValue))
            const newPage = parseInt(event.target.value, 10);
            if (!isNaN(newPage) && newPage > 0 && newPage <= totalpages) {
                changePage(newPage);
            }
            setInputValue(''); 
        }
    }

    return (
        <div className="pagination">
            <button className="pagination-button arrow-button" onClick={(e) => handleClick(e, 'b')}>{'Back'}</button>
            {buttons.map((button, index) => {
                if (typeof(button) === 'number') {
                    return <button className={button === "..." ? "pagination-button dots" : "pagination-button num"} key={index} onClick={(e) => handleClick(e, button)} style={button === pagenum ? {backgroundColor: '#c74066'} : null}>{button}</button>
                } else {
                    return (
                        <input
                            type="text"
                            className="pagination-input"
                            key={index}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={handleInputKeyDown}
                            placeholder="..."
                        />
                    );
                }
                
            })}
            <button className="pagination-button arrow-button" onClick={(e) => handleClick(e, 'f')}>{'Next'}</button>
        </div>
    );
}


function ImageLoader({src, someKey}) {
    const [loaded, setLoaded] = useState(false)
    const toShow = !loaded ? <div className="loader"></div> : <img
        src={src}
        alt="cards"
        onLoad={() => {
            setLoaded(true)}}
    />
    return (
        toShow
    );
}


function InventoryData( {invData, title, setInvData} ) {
    const rarity = [
        "\u273F\u2740\u2740\u2740\u2740",
		"\u273F\u273F\u2740\u2740\u2740",
		"\u273F\u273F\u273F\u2740\u2740",
		"\u273F\u273F\u273F\u273F\u2740",
		"\u273F\u273F\u273F\u273F\u273F"
    ]
    
    const [ascending, setAscending] = useState(true)
    const [sortby, setSortby] = useState("")
    const [pagenum, setPageNum] = useState(1);
    const [filters, setFilters] = useState({
        idol: "",
        group: "",
        rarity: 0,
        era: "",
    })
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setPageNum(1)
    }, [invData]);

    useEffect(() => {
        const data = [].concat(...invData)
        .sort((a, b) => {
            if (sortby === "idol") {
                return a.name.split(" (")[0].localeCompare(b.name.split(" (")[0]);
            } else if (sortby === "group") {
                return a.grop.localeCompare(b.grop);
            } else if (sortby === "rarity") {
                return a.rarity - b.rarity;
            } else if (sortby === "era") {
                if (a.name.split(" (").length === 1 || b.name.split(" (").length === 1) return 1
                return a.name.split(" (")[1].localeCompare(b.name.split(" (")[1]);
            }
        });
        if (!ascending) {
            data.reverse();
        }
        setInvData(data)
    }, [sortby, ascending]);

    if (sortby === "") setSortby("idol");
    const filtersadd = [].concat(...invData)
        .filter(card => {
            if (filters.idol === "") return card
            if (card.name.split(" (")[0].toLowerCase().includes(filters.idol.toLowerCase())) {
                console.log("here yes")
                return card
            }
            return false
        })
        .filter(card => {
            if (filters.group === "") return true
            console.log(filters.group)
            if (card.grop.toLowerCase().includes(filters.group.toLowerCase())) return card
            return false
        })
        .filter(card => {
            if (filters.era === "") return card
            if (card.name.split(" (").length === 2) {
                console.log(card.name.split(" ("))
                if (card.name.split(" (")[1].toLowerCase().includes(filters.era.toLowerCase())) return card
            }
            return false
        })
        
        .filter(card => {
            if (filters.rarity === 0) return card
            if (card.rarity === parseInt(filters.rarity)) return card
            return false
        })
    const onClosed = () => {
        setOpen(false)
    }

    let cards = []
    if (filtersadd.length > 0) {
        for (let i = (pagenum-1)*12; i < pagenum*12; i++) {
            if (i >= filtersadd.length) break
            let card = filtersadd[i]
            cards.push(
                <div className="card" style={{filter: card.grayscale ? "grayscale(100%)" : ""}}>
                    <div className="card-data">
                        <h3>{card.name}</h3>
                        <h4>Group: {card.grop}</h4>
                        <h4>Rarity: <span className="rarity">{rarity[card.rarity-1]}</span></h4>
                        <h4>Copies: {card.copies}</h4>
                        <h4>Card ID: {card.id}</h4>
                    </div>
                    <img src={"https://yujinbot.xyz/api/topsecret/cardlossy?id=" + card.id.replace("#", "h")} key={card.id}/>
                </div>
            )
        }
    }
    return (
        <div className="inventory-data">
            {open && invData.length > 0 ? <InventoryFilters opened={open} onClosed={onClosed} filters={filters} setFilters={setFilters} invData={invData} setPageNum={setPageNum}/>: null}
            <div className="inventory-header">
                <h2>{title}</h2>
            </div>
            <div className="filters-button">
                <div className="cards-num">
                    <h3>Showing {(pagenum-1)*12+1}-{pagenum*12 > filtersadd.length ? filtersadd.length : pagenum*12} Cards</h3>
                </div>
                <div className="filters-settings">
                    <div className="dropdown-sort">
                        <label for="sortby">Sort by: </label>
                        <select id="sortby" onChange={(e) => setSortby(e.target.value)}>
                            <option value="idol">Idol</option>
                            <option value="group">Group</option>
                            <option value="rarity">Rarity</option>
                            <option value="era">Era</option>
                        </select>
                    </div>
                    <div className="sort-type">
                        <label for="sort-order">Sort Order: </label>
                        <select id="sort-order" onChange={(e) =>  setAscending(e.target.value==="asc")}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </div>
                    <button className="filter-button" onClick={() => setOpen(true)}>
                        <img src="/filter.svg" alt="filter icon"/>
                        <p>Filters</p>
                    </button>
                </div>
            </div>
            
            <div className="invcards-container">
                {cards}
            </div>
            <div className="cards-num-bottom">
                    <h3>Showing {(pagenum-1)*12+1}-{pagenum*12 > filtersadd.length ? filtersadd.length : pagenum*12} Cards</h3>
            </div>
            <Pagination pagenum={pagenum} filtersclick={() => setOpen(true)} totalpages={Math.ceil(filtersadd.length/12)} changePage={setPageNum}/>
        </div>
    )
}


export default function Inventory() {
    const [searchParams] = useSearchParams();
    const [invData, setInvData] = useState([])
    const user = searchParams.get("user");
    useEffect(() => {
        const fetchInvDate = async () => {
            axios({
                url: "https://yujinbot.xyz/api/getinventory?user=" + user,
                method: "GET",
            })
            .then(res => {
                setInvData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchInvDate()
    }, [])

    

    return (
        <div>
            <Navbar/>
            <SimpleProfile user={user} inventorycards={invData.length} />
            <InventoryData 
                invData={invData} 
                setInvData={setInvData}
                title={"Your Inventory"}
            />
        </div>
    )
}

export {InventoryData, Autocomplete}