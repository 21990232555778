import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Navbar } from "./home";
import { InventoryData } from "./inventory";
import { Autocomplete } from "./inventory";


function SimpleProfile( {user, currProgress, totalCards }) {
    const [userData, setUserData] = useState([])

    useEffect(() => {
        const fetchUserData = async () => {
            axios({
                url: "https://yujinbot.xyz/api/getuser?user=" + user,
                method: "GET",
            })
            .then(res => {
                setUserData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchUserData()
    }, [])
    const globalname = userData.global_name
    const username = userData.username
    const avatar = "https://cdn.discordapp.com/avatars/" + userData.id + "/" + userData.avatar + ".png"
    return (
        <div className="simple-profile">
            <div className="profile-stuff">
                <div className="avatar">
                    <img src={avatar} alt="User Avatar"></img>
                </div>
                <div className="username">
                    <h2>{globalname}</h2>
                    <h3>{username}</h3>
                </div>
            </div>
            <div className="totalcards">
                <h2>Current Group Progress:</h2>
                <h2>{currProgress}/{totalCards} cards</h2>
            </div>
        </div>
    )
}

function SelectProgress({folders, setFolder, folder}) {
    return (
        <div className="folder-select">
            <h2>Select Group</h2>
            <Autocomplete options={folders} setValue={setFolder} value={folders[0]} untilset={true} defaul={folder}/>
        </div>
    )
}


export default function Progress() {
    const [cards, setCards] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [group, setGroup] = useState(searchParams.get("group") ? searchParams.get("group") : "")
    const [currProgress, setCurrProgress] = useState(0)
    const [invData, setInvData] = useState([])
    const [progressCards, setProgressCards] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [allGroups, setAllGroups] = useState([])


    const firstUpdate = React.useRef(true);
    const user = searchParams.get("user")


    useEffect(() => {
        const fetchUserData = async () => {
            await axios({
                url: "https://yujinbot.xyz/api/getcards",
                method: "GET",
            })
            .then(res => {
                setCards(res.data)
                setAllGroups([...new Set(res.data.map(obj => obj.grop))])
            })
            .catch(err => {
                console.log(err)
            })
        }
        const fetchInvDate = async () => {
            await axios({
                url: "https://yujinbot.xyz/api/getinventoryfull?user=" + user,
                method: "GET",
            })
            .then(res => {
                console.log(res.data)
                setInvData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
        
        fetchUserData()
        fetchInvDate()
    }, [])

    
    const updateProgressCards = async() => {
        let num = 0
        const newData = cards
        .filter(card => card.grop === group)
        .map(obj => {
            if (!invData.includes(obj.id)) {
                
                return {...obj, grayscale: true} 
            }
            num++
            return obj
        })
        setProgressCards(newData)
        setCurrProgress(num)
    }


    useEffect(() => {
        updateProgressCards()
    }, [group, refresh])


    return (
        <div>
            <Navbar/>
            <SimpleProfile user={user} currProgress={currProgress} totalCards={progressCards.length}/>
            <SelectProgress folders={allGroups} setFolder={setGroup} folder={group}/>
            <InventoryData invData={progressCards} setInvData={setProgressCards} title={"Group: " + group}/>
        </div>
    )
}