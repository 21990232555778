import React from "react";
import { Navbar } from "./home";
import "./main.css";


export default function Commands() {
    const commands = {
        "Cards": {
            "/drop": "Drops a set of 3 cards",
            "/inventory": "Shows Inventory",
            "/show card": "Shows information about a card",
            "/search": "Search a card with its name and group!",
            "/cooldowns": "Shows your current cooldowns",
            "/progress": "Shows your progress in collecting a certain group!",
        },
        "Currency": {
            "/daily": "Claim your daily Petals!",
            "/balance": "Shows the amount of Petals a user owns.",
            "/shop card": "Yujin's card marketplace!",
            "/shop powerups": "A marketplace for items to boost up your claiming Experience",
            "/buy": "Buy a card with your Petals!",
            "/sell": "Sell your cards for some Petals!",
            "/task": "Do a task every 2 hours to earn some Petals!",
            "/gift card": "Gift a card to your friend.",
            "/gift petals": "Gift Petals to your friend!",
        },
        "Folders": {
            "/folder create": "Bind some of your favourite cards into a folder!",
            "/folder show": "Shows a folder that you have created",
            "/folder list": "Lists all folders owned by a user",
            "/folder delete": "Deletes one of your folders",
            "/folder add_card": "Add a single card into a folder!",
            "/folder remove_card": "Removes a single card from a folder!",
        },
        "Fun": {
            "minesweeper": "Play a minesweeper game!",
            "memory": "A traditional memory game",
        },
        "Utility": {
            "/profile": "Shows profile",
            "/favourite": "Sets a favourite card in your profile",
            "/ping": "Shows bot's latency",
            "/binder new": "Combine your 5 favourite cards into a binder!",
            "/binder edit": "Edit cards in one your binders!",
            "/binder show": "Shows your binder",
            "/help": "A guide to Yujin!",
            "/vote": "Vote for Yujin to get awesome prizes!",
        },
        "Powerups": {
            "/powerups show": "Shows powerups in your inventory",
            "/powerups use": "Use a powerup you own",
            "/powerups buy": "Buy a powerup.",
            "/powerups current": "Shows your current active boosts",
        }
    }
    return (
        <div>
            <Navbar />
            <div className="commands">
                <h1>Commands</h1>
                <div className="commands-container">
                    {Object.keys(commands).map((category) => (
                        <div key = {category} className="category">
                            <h2>{category} Commands</h2>
                            <div className="category-container">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Command</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(commands[category]).map((command) => (
                                            <tr>
                                                <td key={command}>{command}</td>
                                                <td key={commands[category][command]}>{commands[category][command]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
