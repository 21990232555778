import ReactDOM from 'react-dom/client';
import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './home';
import Inventory from './inventory';
import Commands from './commands';
import Folders from './folders';
import Progress from './progress';
import PP from './pp';
import Cards from './totalcards';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/inventory" element={<Inventory />} />
                <Route path="/commands" element={<Commands />} />
                <Route path='/folders' element={<Folders/>} />
                <Route path='/privacy-policy' element={<PP />} />
                <Route path='/progress' element={<Progress />} />
                <Route path='/cards' element={<Cards />} />
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>)