import { useEffect, useState } from "react";
import { Navbar } from "./home";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Autocomplete, InventoryData } from "./inventory";


function SimpleProfile( {user, folders, foldercards }) {
    const [userData, setUserData] = useState([])

    useEffect(() => {
        const fetchUserData = async () => {
            axios({
                url: "https://yujinbot.xyz/api/getuser?user=" + user,
                method: "GET",
            })
            .then(res => {
                setUserData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchUserData()
    }, [])
    const globalname = userData.global_name
    const username = userData.username
    const avatar = "https://cdn.discordapp.com/avatars/" + userData.id + "/" + userData.avatar + ".png"
    return (
        <div className="simple-profile">
            <div className="profile-stuff">
                <div className="avatar">
                    <img src={avatar} alt="User Avatar"></img>
                </div>
                <div className="username">
                    <h2>{globalname}</h2>
                    <h3>{username}</h3>
                </div>
            </div>
            <div className="totalcards">
                <h2>Folders:</h2>
                <h2>{folders}</h2>
            </div>
            <div className="totalcards">
                <h2>Foldered Cards:</h2>
                <h2>{foldercards}</h2>
            </div>
        </div>
    )
}

function SelectFolder({folders, setFolder, folder}) {
    return (
        <div className="folder-select">
            <h2>Select Folder</h2>
            <Autocomplete options={folders} setValue={setFolder} value={folders[0]} untilset={true} defaul={folder}/>
        </div>
    )
}

export default function Folders() {
    const [folderData, setFolderData] = useState([])
    const [folder, setFolder] = useState("")
    const [foldercards, setFolderCards] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const user = searchParams.get("user");

    useEffect(() => {
        const fetchUserData = async () => {
            axios({
                url: "https://yujinbot.xyz/api/getfolders?user=" + user,
                method: "GET",
            })
            .then(res => {
                setFolderData(res.data)
                setFolder(res.data[0].name)
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchUserData()
    }, [])
    const getTotalCards = () => {
        let total = 0
        for (const value of Object.values(folderData)) {
            total += value.cards.length
        }
        return total
    }
    const folders = folderData.map(folder => folder.name)
    const total = getTotalCards()

    useEffect(() => {
        for (const value of Object.values(folderData)) {
            if (value.name === folder) {
                setFolderCards(value.cards)
            }
        }
    }, [folder])
    
    return (
        <div>
            <Navbar/>
            <SimpleProfile user={user} folders={folders.length} foldercards={total}/>
            <SelectFolder folders={folders} setFolder={setFolder} folder={folder}/>
            
            {foldercards.length > 0 && <InventoryData title={folder} invData={foldercards} setInvData={setFolderCards}/>}
        </div>
    )
}